<template>
  <ContentWrapper>
    <breadcrumb name="Swap" />

    <div class="container animated-fast fadeInUp">

      <!-- Einleitung -->
      <div class="card card-default mb-4">
        <div class="card-header">
          Umwandlung von Pflanzen in Genusscheine
        </div>
        <div class="card-body">
          <p>
            Bei der Cannerald AG hast Du die Möglichkeit, Deine gekauften Pflanzen in Genusscheine umzuwandeln.
            Für jede Pflanze, die Du besitzt, kannst Du für 24,50 € einen Genusschein erwerben, der Dir zusätzliche
            Vorteile bietet. Der Umwandlungsprozess ermöglicht es Dir, von zukünftigen Erträgen und möglichen Wertsteigerungen
            zu profitieren.
          </p>
          <p>
            Nutze die folgenden Unterseiten, um mehr über den Umwandlungsprozess zu erfahren, häufig gestellte Fragen (FAQ) zu klären und Prognosen
            zu sehen sowie Berechnungen für mögliche Einnahmen durchzuführen:
          </p>
        </div>
      </div>

      <!-- Menüauswahl -->
      <div class="btn-toolbar text-center mb-3">
        <b-btn variant="primary" size="xl" class="mr-2" :to="{ name: 'Growing:Participation' }">Pflanzen Umwandeln</b-btn>
        <b-btn variant="primary" size="xl" class="mr-2" :to="{ name: 'Growing:ParticipationFaq' }">FAQ + Videos</b-btn>
        <b-btn variant="primary" size="xl" class="mr-2" :to="{ name: 'Growing:ParticipationPrediction' }">Prognosen + Rechner</b-btn>
      </div>

      <!-- Übersicht getauschter Pflanzen -->
      <div class="card card-default mb-4">
        <div class="card-header">
          Übersicht getauschter Pflanzen
        </div>
        <div class="card-body">
          <div class="row text-center">
            <!-- Bereits getauschte Pflanzen -->
            <div class="col-md-6 mb-4">
              <div class="p-3 border rounded bg-light">
                <h5>Bereits getauschte Pflanzen</h5>
                <p class="display-4 text-primary">{{ swappedPlants }}</p>
              </div>
            </div>
            <!-- Verfügbare Pflanzen zum Tauschen -->
            <div class="col-md-6 mb-4">
              <div class="p-3 border rounded bg-light">
                <h5>Verfügbare Pflanzen zum Tauschen</h5>
                <p class="display-4 text-warning">{{ swappablePlants }}</p>
              </div>
            </div>
          </div>
          <!-- Statusanzeige -->
          <div v-if="swappablePlants > 0" class="alert alert-warning" role="alert">
            Es sind noch Pflanzen verfügbar, die Du umtauschen kannst!
          </div>
          <div v-else class="alert alert-primary" role="alert">
            Du hast entweder bereits alle Pflanzen zum Umtausch angemeldet oder keine Pflanzen, die für den Umtausch gültig sind!
          </div>

          <!-- Hinweis zum Tauschprozess -->
          <div class="alert alert-info mt-4" role="alert" v-if="swappablePlants > 0">
            <strong>Hinweis:</strong> Der Tausch wird erst nach Zahlungseingang abgeschlossen. Bitte achte darauf, dass die Zahlung rechtzeitig erfolgt, um den Umtausch erfolgreich abzuschließen.
          </div>
        </div>
      </div>


      <!-- Dynamischer Inhalt -->
      <router-view></router-view>

      <!-- Kaufbereich -->
      <div class="card card-default card-buy-now mt-4" v-if="term && product && swappablePlants > 0">
        <div class="card-body">
          <div>
            <payment-selector
                :product="product"
                :quantity-preselected="swappablePlants"
                :quantity-limit="swappablePlants"
                :quantity-selector="false"
                :terms="product.agreement.url"
            ></payment-selector>
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</template>

<style lang="scss">
</style>

<script>
import PaymentSelector from "../../Wallet/components/PaymentSelector";

export default {
  components: {
    PaymentSelector,
  },
  data() {
    return {
      term: null,
      product: null,
      swappablePlants: 0,
      swappedPlants: 0,
      category: null,
    };
  },
  mounted() {},
  beforeCreate() {
    this.$api.get("user/term/plantcontract").then((response) => {
      this.term = response.data.data;
    });
    this.$api.get("growing/plants/participation-overview").then((response) => {
      this.swappablePlants = response.data.swappable_plants;
      this.swappedPlants = response.data.swapped_plants;
    });
    this.$api.get("growing/plants/product/22").then((response) => {
      this.product = response.data.product;
    });
  },
  methods: {},
};
</script>
